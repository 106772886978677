
<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-skeleton-loader class="ma-3 pa-2" v-if="loading" v-bind="attrs" type="list-item-three-line"></v-skeleton-loader>
        <v-card v-else>
            <v-toolbar flat class="tc-title" dark>
                <v-toolbar-title>Placement Dashboard</v-toolbar-title>
            </v-toolbar>
            <v-tabs slider-color="white" icons-and-text :vertical="vertical_var">
                <v-tab style="margin: 5px ;" @click="renderincrement('Current')">
                    Current
                </v-tab>
                <v-tab style="margin: 5px ;" @click="renderincrement('Program')">
                    Program Wise
                </v-tab>
                <v-tab style="margin: 5px ;" @click="renderincrement('Skill')">
                    Skill Wise
                </v-tab>
                <v-tab style="margin: 5px ;" @click="renderincrement('Location')">
                    Location Wise
                </v-tab>
                <v-tab style="margin: 5px ;" @click="renderincrement('Company')">
                    Company Wise
                </v-tab>
                <v-tab style="margin: 5px ;" @click="renderincrement('OverAll')">
                    OverAll
                </v-tab>

                <v-tab-item>
                    <Current_dashboard :ay_list="ay_list" :current_ay="current_ay" :current_org="current_org"
                        :org_list="org_list" :key="Current_dashboard_key"></Current_dashboard>
                </v-tab-item>
                <v-tab-item>
                    <program_wise_dashboard :ay_list="ay_list" :current_ay="current_ay" :current_org="current_org"
                        :org_list="org_list" :key="Program_dashboard_key"></program_wise_dashboard>
                </v-tab-item>
                <v-tab-item>
                    <skill_wise_dashboard :ay_list="ay_list" :current_ay="current_ay" :current_org="current_org"
                        :org_list="org_list" :key="Skill_dashboard_key"></skill_wise_dashboard>
                </v-tab-item>
                <v-tab-item>
                    <location_dashboard :ay_list="ay_list" :current_ay="current_ay" :current_org="current_org"
                        :org_list="org_list" :key="Location_dashboard_key"></location_dashboard>
                </v-tab-item>
                <v-tab-item>
                    <companywise_dashboard :ay_list="ay_list" :current_ay="current_ay" :current_org="current_org"
                        :org_list="org_list" :key="Location_dashboard_key"></companywise_dashboard>
                </v-tab-item>
                <v-tab-item>
                    <overall_dashboard :ay_list="ay_list" :current_ay="current_ay" :current_org="current_org"
                        :org_list="org_list" :key="OverAll_dashboard_key"></overall_dashboard>
                </v-tab-item>

            </v-tabs>
        </v-card>
    </div>
</template>
<script>
import Current_dashboard from "@/components/dashboard/Current_dashboard.vue";
import program_wise_dashboard from "./program_wise_dashboard.vue";
import skill_wise_dashboard from "./skill_wise_dashboard.vue";
import overall_dashboard from "@/components/dashboard/overall_dashboard.vue";
import location_dashboard from "@/components/dashboard/location_dashboard.vue";
import companywise_dashboard from './companywise_dashboard.vue';
import axios from "axios";
export default {
    components: {
        Current_dashboard,
        program_wise_dashboard,
        skill_wise_dashboard,
        overall_dashboard,
        location_dashboard,
        companywise_dashboard,
    },
    data: () => ({
        tab: 0,
        Current_dashboard_key: 0,
        OverAll_dashboard_key: 0,
        Company_dashboard_key: 0,
        Location_dashboard_key: 0,
        Skill_dashboard_key: 0,
        Program_dashboard_key: 0,

        ay_list: [],
        current_ay: "",
        loading: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        vertical_var: false,
        org_list: [],
        current_org: "",
    }),
    mounted() {

        this.init();
        this.$store.state.drawer = false;
        var x = window.matchMedia("(max-width: 700px)")
        if (x.matches) {
            this.vertical_var = false;
        } else {
            this.vertical_var = true;
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.post("/Dashboard/getaAcademicYearAndOrg").then((res) => {
                if (res.data.msg == "200") {
                    this.ay_list = res.data.aylist;
                    this.org_list = res.data.orglist;
                    this.current_ay = res.data.current_ay;
                    this.current_org = this.org_list[0];
                    this.loading = false;
                } else {
                    this.showSnackbar("red", res.data.msg);
                    this.loading = false;
                }
            });
        },
        renderincrement(value) {
            if (value == 'OverAll')
                this.OverAll_dashboard_key++;
            if (value == 'Company')
                this.Company_dashboard_key++;
            if (value == 'Package')
                this.Package_dashboard_key++;
            if (value == 'Location')
                this.Location_dashboard_key++;
            if (value == 'Skill')
                this.Skill_dashboard_key++;
            if (value == 'Program')
                this.Program_dashboard_key++;
            if (value == 'Current')
                this.Current_dashboard_key++;
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    }
};



</script>
<style scoped> .tc-title {
     background-image: linear-gradient(-90deg, skyblue, #057996);
     color: #fff;
     border-radius: 3px;
 }

 .v-tab--active {
     background-image: linear-gradient(-90deg, skyblue, #057996);
     color: #fff;
     border-radius: 3px;

 }

 @media only screen and (max-width: 600px) {
     element.relevantclass {
         display: none;
     }
 }

 .v-slide-group__prev v-slide-group__prev--disabled {
     display: initial;
     visibility: hidden;
 }
</style>
