
<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="ma-2">
            <v-col cols="12" sm="3" md="3">
                <v-select v-model="selected_ay" label="Academic Year" :items="ay_list" item-text="ay" item-value="id"
                    outlined></v-select>
            </v-col>
            <v-col cols="12" sm="3" md="3">
                <v-select v-model="selected_org" label="Organization" :items="org_list" item-text="name" item-value="id"
                    outlined></v-select>
            </v-col>
            <v-col cols="12" sm="3" md="3">
                <v-btn class="mt-2" color="primary darken-1" @click="fetchReport()">Fetch Report</v-btn>

            </v-col>
        </v-row>
        <v-skeleton-loader class="ma-3 pa-2" v-if="dialog_loading" v-bind="attrs"
            type="list-item-three-line"></v-skeleton-loader>
        <div v-else>

            <div class="ma-3" v-if="Array.isArray(location_wise_count_list) &&
                location_wise_count_list.length">

                <fieldset style="border-color: white;" class="pa-3">
                    <legend>Location-Wise Company</legend>
                   
                        <barchart style="transform: rotate(90deg);" :data="program_bar_data" :key="rerender" :options="options" />
                   
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <center>
                                <linechart :data="program_bar_data" :key="rerender1" :options="options" />
                            </center>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-simple-table id="exceltable">
                                <tbody>
                                    <tr>
                                        <th>Location</th>
                                        <th>Company Count</th>

                                    </tr>
                                    <tr v-for="(item, index1) in location_wise_count_list" :key="index1">

                                        <td> {{ item[0].name }} </td>

                                        <td> {{ item[1] }} </td>

                                    </tr>

                                </tbody>
                            </v-simple-table>
                        </v-col>

                    </v-row>

                </fieldset>

            </div>



        </div>
    </div>
</template>
<script>

import axios from "axios";
import linechart from '../TPO/chart/line_chart.vue'
import barchart from '../TPO/chart/bar_chart.vue'

export default {
    components: { linechart, barchart },
    data: () => ({
        rerender1: 0,
        rerender:0,
        selected_ay: "",

        snackbar_msg: "",
        color: "",
        snackbar: false,
        dialog_loading: false,
        flag: false,
        selected_org: "",
        location_wise_count_list: [],
        chartOptions: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    },

                }]
            },
            responsive: true,
        },
        options: {
        scales: {
            xAxes: [{
                ticks: {
                   
                    maxRotation: 90,
                    minRotation: 90
                }
            }],
            yAxes: [{
                ticks: {
                   
                    maxRotation: 90,
                    minRotation: 90
                }
            }]
        }
    },
        program_bar_data: "",

    }),

    props: ['ay_list', 'current_ay', 'org_list', 'current_org'],
    mounted() {

        this.selected_ay = this.current_ay;
        this.selected_org = this.current_org.id;

    },
    methods: {


        fetchReport() {

            if (this.selected_ay == '' || this.selected_ay == null) {
                this.showSnackbar("red", "PLease Select  Academic Year");
                return;
            }
            if (this.selected_org == '' || this.selected_org == null) {
                this.showSnackbar("red", "PLease Select Ogranization");
                return;
            }
            this.location_wise_count_list = [];
            this.dialog_loading = true;
            this.flag = true;
            this.program_bar_data = "";
            const data = {
                selected_org: this.selected_org,
                selected_ay: this.selected_ay,
            };
            axios
                .post("/Dashboard/location_wise_company_count", data)
                .then(res => {
                    if (res.data.msg == "200") {
                        this.rerender1++;
                        this.rerender++;
                        this.dialog_loading = false;

                        this.location_wise_count_list = res.data.location_wise_count_list;
                        if ((this.location_wise_count_list != 'undefined' || this.location_wise_count_list.length > 0)) {
                            var label = [];
                            var company_data = [];
                            var total = 0
                            for (var x in this.location_wise_count_list) {
                                label.push(this.location_wise_count_list[x][0].name);
                                company_data.push(this.location_wise_count_list[x][1]);

                                total += this.location_wise_count_list[x][1];

                            }
                            this.program_bar_data = { "labels": label, "datasets": [{ "data": company_data, "label": "Location Wise Company", "backgroundColor": 'skyblue', }] };

                            var map = { "name": "TOTAL", "id": "TOTAL" };
                            var array = []
                            array.push(map);
                            array.push(total);
                            this.location_wise_count_list.push(array);

                        }
                    }

                    else {
                        this.dialog_loading = false;
                        this.showSnackbar("red", res.data.msg);
                    }
                });
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    }
};
</script>
<style scoped>
.tc-title {
    background-image: linear-gradient(-90deg, skyblue, #057996);
    color: #fff;
    border-radius: 3px;
}

legend {
    background-color: gray;
    color: white;
    padding: 5px 10px;
}

.custom-form-box {
    border: 2px solid;
    padding: 10px;
}
</style>
